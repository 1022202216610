export const isDevEnvironment = [
  "localhost",
  "10.0.2.2",
  "appdev.forthwithlife.co.uk",
  "appdev.sportsbloodtests.co.uk",
  "appdev.biomarkertracking.com",
].some((hostname) => hostname == window.location.hostname);

const SHOP_URLS = {
  1: "https://shop.forthwithlife.co.uk",
  2: "https://shop.sportsbloodtests.co.uk",
  3: "https://shop.biomarkertracking.com",
};

const DEV_SHOP_URLS = {
  1: "https://appdev.forthwithlife.co.uk",
  2: "https://appdev.sportsbloodtests.co.uk",
  3: "https://appdev.biomarkertracking.com",
};

const siteId = window.config?.websiteId || 1;

export const getShopHostname = (websiteId) => {
  if (isDevEnvironment) {
    return DEV_SHOP_URLS[websiteId] ?? window.location.origin;
  }

  if (websiteId) return SHOP_URLS[websiteId] ?? window.location.origin;
  return SHOP_URLS[siteId] ?? window.location.origin;
};
