export const extractDomain = (value) => {
  const url = new URL(value);

  if (!url.hostname) {
    return null;
  }

  const hostnameParts = url.hostname.split(".");
  if (hostnameParts.length <= 2) {
    return url.hostname;
  }

  const prefixes = ["com", "co", "org", "net", "gov", "edu"];
  const potentialTwoPartTLD = `${hostnameParts[hostnameParts.length - 2]}.${
    hostnameParts[hostnameParts.length - 1]
  }`;

  
  return prefixes.includes(hostnameParts[hostnameParts.length - 2])
    ? `${hostnameParts[hostnameParts.length - 3]}.${potentialTwoPartTLD}`
    : hostnameParts.slice(-2).join(".");
};
